import React from 'react';

// libraries
import { css } from '@emotion/core';

import Head from 'components/Head';
import Callout from 'components/Callout';
import MainLayout from 'components/MainLayout';
import { button, media, Vars } from 'style';

import hero from 'images/licensing/hero.png';
import hero2x from 'images/licensing/hero@2x.png';
import build from 'images/licensing/build.png';
import build2x from 'images/licensing/build@2x.png';
import onetool from 'images/licensing/onetool.png';
import onetool2x from 'images/licensing/onetool@2x.png';
import track from 'images/licensing/track.png';
import track2x from 'images/licensing/track@2x.png';

export default function EnterpriseLanding() {
  return (
    <MainLayout hero>
      <Head
        title="License Game Streaming Technology - Developer Tools"
        description="Integrate the world's best game streaming technology into your game, platform, or application to deliver content via high frame rate, low latency streaming."
      />
      <section css={heroStyles}>
        <div>
          <h1 css={title}>License Parsec for Your Application</h1>
          <p css={subtitle}>
            An SDK for ultra-low latency, high frame rate video streaming
            technology built directly into your product.
          </p>
        </div>
        <div>
          <a
            href="https://bit.ly/parsec-sdk-form"
            css={[
              button({ kind: 'brand', size: 'xlarge' }),
              { margin: '0 auto 1.2rem' }
            ]}
          >
            Get in Touch
          </a>
          <p css={blurb}>
            Manage user accounts and customize the client experience while
            taking advantage of Parsec’s technology.
          </p>
        </div>
      </section>

      <section css={calloutWrapper()}>
        <Callout
          subtitle="Cross Platform"
          title="Build once. Get smooth 60FPS 4K everywhere."
          text="Now you can create new experiences with your software on any platform. Support Windows, macOS, Android, iOS, Linux, and your web browser via our ultra–low latency cross–platform streaming technology."
          image={{
            x1: build,
            x2: build2x
          }}
          maxImageWidth={600}
        >
          <a
            href="https://github.com/parsec-cloud/parsec-sdk"
            css={button({ kind: 'positive', size: 'large' })}
          >
            Download the SDK
          </a>
        </Callout>
      </section>

      <div css={calloutWrapper()}>
        <Callout
          subtitle="Unlimited Power"
          title="Low latency streaming. Multiple use cases."
          text="The world’s biggest companies use Parsec to power their cloud gaming businesses, virtual desktop infrastructure, app streaming, MPEG Transport Stream broadcasts, and on–prem simulation software."
          image={{
            x1: onetool,
            x2: onetool2x
          }}
          maxImageWidth={600}
        >
          <a
            href="/game-streaming-technology"
            css={button({ kind: 'positive', size: 'large' })}
          >
            Our Technology
          </a>
        </Callout>
      </div>

      <section css={calloutWrapper(true)}>
        <Callout
          subtitle="Proven Track Record"
          title="Built for gaming, and great for any application."
          text="Building the best low latency game streaming technology requires years of development, but with millions of users and licensing partnerships with Samsung, HP, and many others, you can count on Parsec to deliver the best–in–class experience."
          image={{
            x1: track,
            x2: track2x
          }}
          maxImageWidth={600}
        >
          <a
            href="https://github.com/parsec-cloud/parsec-sdk/blob/master/api/enterprise/README.md"
            css={button({ kind: 'positive', size: 'large' })}
          >
            View API Documentation
          </a>
          <a href="/docs/sdk" css={button({ kind: 'positive', size: 'large' })}>
            View SDK Documentation
          </a>
        </Callout>
      </section>
    </MainLayout>
  );
}

const heroStyles = css`
  background: #000000 center url(${hero}) no-repeat;
  background-size: 57.6rem;
  text-align: center;
  height: 100vh;
  min-height: 60rem;
  padding: 6rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.hidpi} {
    background-image: url(${hero2x});
  }

  &:before {
    content: '';
    display: block;
  }

  @media screen and (min-width: 480px) {
    background-size: 120%;
  }

  @media screen and (min-width: 800px) {
    background-size: 141rem;
  }
`;

const title = css`
  font-size: ${Vars.FontSizeTitle};
  line-height: ${Vars.LineHeightTitle};
  text-shadow: 0 0.2rem 0.4rem ${Vars.Shade600};
`;

const subtitle = css`
  font-size: ${Vars.FontSizeH4};
  line-height: ${Vars.LineHeightH4};
  text-shadow: 0 0.2rem 0.4rem ${Vars.Shade600};
  font-weight: 700;
  margin-bottom: 3rem;
`;

const blurb = css`
  margin-top: 1rem;
  font-weight: bold;
  font-size: ${Vars.FontSizeH3};
  line-height: ${Vars.LineHeightH3};
  max-width: 80rem;
  margin: 1rem auto 0 auto;
`;

const calloutWrapper = (bottom = false) => css`
  background-color: #000000;
  padding-bottom: ${bottom ? '10rem' : 0};
`;
